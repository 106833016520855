import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { TopNav } from "./top-nav";

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#F4F4F4",
  minHeight:"100vh"
  
}));

export const Layout = () => {
  return (
    <LayoutRoot>
      <TopNav />
      <Container maxWidth="sm" sx={{ paddingX: "0px" }}>
        <Outlet />
      </Container>
    </LayoutRoot>
  );
};
