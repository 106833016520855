const typography = {
  fontFamily: "Hiragino Sans",

  b20_130: {
    fontWeight: 500,
    lineHeight: "130%",
    fontSize: "20px",
    letterSpacing: "0.6px",
  },
  b14_300: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: "-0.0033px",
  },
  b14_300_140: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.6px",
  },
  b14_300_170: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "170%",
  },
  b14_500_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
  },
  b16_300: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "-0.33px",
  },
  b16_300_170: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "170%",
  },
  b16_300_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "150%",
  },
  b16_500_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "-0.33px",
  },
  b16_500_175: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "175%",
  },
  b16_500_130: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "130%",
  },
  b18_500_130: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "130%",
  },
  b14_300_175: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "175%",
  },
  b18_500_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "-0.33px",
  },
  b18_500: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "100%",
    letterSpacing: "-0.33px",
  },
  b12_500: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: "-0.0033px",
  },
  b12_500_110: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "90%",
  },
  b12_300_130: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "130%",
    letterSpacing: "0.6px",
  },
  b12_500_130: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "130%",
    letterSpacing: "0.6px",
  },
  b12_300_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "150%",
    letterSpacing: "0.6px",
  },
  b10_300_150: {
    fontFamily: "Hiragino Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "150%",
    letterSpacing: "0.6px",
  },
};

export default typography;
