export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          miniHeight: "40px",
          background: theme.palette.common.white,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root.Mui-focused": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "100%",
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          textAlign: "start",
          color: theme.palette.primary.main,
          height: "40px",

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            boxShadow: "inset 0px 4px 6px rgba(44, 54, 156, 0.1)",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: theme.palette.primary.main,
            },
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1.5,
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
