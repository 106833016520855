import { Box } from "@mui/material";
import RadioIcon from "src/assets/theme/radio-disable.svg";
import RadioCheckedIcon from "src/assets/theme/radio-checked.svg";

export default function Radio(theme) {
  return {
    MuiRadio: {
      defaultProps: {
        icon: <Box component="img" src={RadioIcon} height="32px" />,
        checkedIcon: (
          <Box component="img" src={RadioCheckedIcon} height="32px" />
        ),
      },

      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: theme.spacing(1),
          ...(ownerState.size === "small" && {
            "& svg": { width: 32, height: 32 },
          }),
          ...(ownerState.size === "medium" && {
            "& svg": { width: 32, height: 32 },
          }),
        }),
      },
    },
  };
}
