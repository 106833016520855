import { BrowserRouter } from "react-router-dom";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import ScrollToTop from "src/components/scroll-to-top";

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}
