const GREY = {
  50: "#FAFAFA",
  100: "#F2F2F2",
  150: "#E6E6E6",
  200: "#D0D0D0",
  250: "#9F9F9F",
};

const PRIMARY = {
  main: "#5470D6",
  light: "#ECF1FF",
  lighter: "#CCD6F4",
};

const SECONDARY = {
  main: "#7ECDF8",
};

const INFO = {
  main: "#5FACFF",
};

const SUCCESS = {
  main: "#4D810F",
};

const WARNING = {
  main: "#C5811B",
};

const ERROR = {
  main: "#FF538A",
};

const palette = {
  common: { black: "#000000", white: "#FFFFFF" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  text: {
    primary: "#1A1C21",
    secondary: "#333333",
    disabled:"#323232",
    disclaimer: "#4F4F4F"
  },
  background: {
    default: "#FFFFFF",
    paper: "#FAFAFA",
    neutral: "#CCD6F4",
    button: "linear-gradient(90.85deg, #7ECDF8 1.57%, #588AF0 58.46%)",
  },
};

export default palette;
