import { useRoutes } from "react-router-dom";
// layout
import { Layout } from "src/layouts";
// .
import {
  TopPage,
  Disclaimer,
  Step01,
  Step02,
  LoadResult,
  AIResult,
  VariableInsurance,
  ServerStatus,
} from "./elements";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/top", element: <TopPage /> },
        { path: "/disclaimer", element: <Disclaimer /> },
        { path: "/step-01", element: <Step01 /> },
        { path: "/step-02", element: <Step02 /> },
        { path: "/load-result", element: <LoadResult /> },
        { path: "/results", element: <AIResult /> },
        { path: "/variable-insurance", element: <VariableInsurance /> },
        { path: "/status", element: <ServerStatus /> },
      ],
    },
  ]);
}
