import { Suspense, lazy } from "react";

const Loadable = (Component) => (props) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

export const TopPage = Loadable(lazy(() => import("../pages/top-page")));
export const Disclaimer = Loadable(lazy(() => import("../pages/disclaimer")));
export const LoadResult = Loadable(lazy(() => import("../pages/load-result")));
export const ServerStatus = Loadable(lazy(() => import("../pages/server-status")));

export const Step01 = Loadable(lazy(() => import("../pages/step-one")));
export const Step02 = Loadable(lazy(() => import("../pages/step-two")));

export const AIResult = Loadable(lazy(() => import("../pages/ai-result")));
export const VariableInsurance = Loadable(
  lazy(() => import("../pages/variable-insurance"))
);
