// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
// assets
import Logo from "src/assets/logs/top-logo.svg";

export const TopNav = () => {
  const theme = useTheme();

  return (
    <Box
      component="header"
      sx={{
        left: 0,
        position: "sticky",
        right: 0,
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          height: "80px",
          position: "relative",
          paddingX: "0px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            padding: "13px",
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box height="20px">
            <Box component="img" src={Logo} />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "30px",

              borderRadius: "0px 40px",
              background:
                "linear-gradient(-90.85deg, #7ECDF8 1.57%, #588AF0 58.46% ) top right, linear-gradient(#7ECDF8,#7ECDF8)bottom right",
              backgroundSize: "100% 50%",
              backgroundRepeat: "no-repeat",

              marginTop: "5px",
              paddingLeft: "27px",

              fontFamily: "Hiragino Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "30px",
              letterSpacing: "0.6px",
              color: theme.palette.common.white,
            }}
          >
            あんしんロボアド
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
